.blender{
	&__container{
		@include min(600px) {
			display: block;
			padding-bottom: 400px;
			&::after{
				content: normal;
			}
			.blender{
				background: $color-light url(/images/local/blender/v-202411080924/0-str.jpg) no-repeat 50% 100% / cover;
			}
			&.-nosidebar{
				padding-bottom: 680px;
				.blender{
					background-image: url(/images/local/blender/v-202411080924/0-str-nosidebar.jpg);
				}
			}
		}
		@include min(800px){
			&.-nosidebar{
				padding-bottom: 580px;
			}
		}
		@include min(1024px) {
			padding-bottom: 350px;
		}
		@include min(1280px) {
			padding-bottom: 350px
		}
		@include min(1920px) {
			padding-bottom: 465px;
		}
	}
}
