.blender {
	@include fill;
	z-index: 2;
	display: none;
	background: $color-dark url(/images/local/blender/v-202411080924/0.jpg) no-repeat 50% 50% / cover;
	@include min(600px) {
		display: block;
		&__container {
			padding-bottom: 550px;
			&::after{
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				background: transparent url(/images/local/v-202411080924/blender-shadow-w.png) repeat-x 0 100%;
				height: 294px;
				z-index: 30;
			}
		}
		&__buttons{
			display: flex;
		}
	}
	@include min(1280px) {
		&__container {
			padding-bottom: ratio(1920px, 855px);
		}
	}
	@include min(1920px) {
		&__container {
			padding-bottom: 855px;
		}
	}

	&__container {
		position: relative;
	}

	&__buttons {
		position: absolute;
		z-index: 15;
		right: 3%;
		bottom: 2rem;
		display: none;
		&[data-blender-buttons]{
			right: 50%;
			transform: translateX(50%);
		}
	}

	&__button {
		@include flex(df,aic,jcc);
		padding: 0;
		border-radius: 0;
		background: transparent none;
		box-shadow: none;
		width: 50px;
		height: 50px;
		background-color: #bfbfbf;
		cursor: pointer;
		&--active {
			background-color: #dddddd;
		}
		svg{
			fill: $color-dark;
		}
		@include link-over(&){
			background-color: $color-secondary;
			color: $color-light;
			svg{
				fill: $color-light;
			}
		}
	}


	@keyframes blend-in {
		from {
			display: block;
			opacity: 0;
		}

		to {
			display: block;
			opacity: 1;
		}
	}

	&__item {
		@include fill;

		background: {
			position: 50% 50%;
			repeat: no-repeat;
			size: cover;
		}

		&.-animating {
			animation: blend-in 800ms ease-out;
			animation-fill-mode: forwards;
		}
	}

	&__title,
	&__description {
		position: absolute;
		right: 0em;
		bottom: 4%;
		z-index: 51;
	}

	&__description {
		color: #ffffff;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 35.8em;
		padding: 1rem 1em 1rem 6%;
		max-width: 55%;
		height: 140px;

		@include link {
			color: #ffffff;
			font-size: rem(21px);
			font-weight: 600;
			font-family: $font-headings;
			margin: 0 0 .25em;
			text-decoration: underline;
		}

		@include link-over {
			text-decoration: none;
		}

		strong {
			font-size: rem(21px);
			font-weight: 600;
			font-family: $font-headings;
			margin: 0 0 .25em;
		}

		span {
			font-size: rem(16px);
		}

		&:before {
			position: absolute;
			content: "";
			top: 0px;
			bottom: 0px;
			left: 0;
			right: -40px;
			background-color: rgba(#000000, .75);
			transform: skew(-14.5deg);
			z-index: -1;
		}
	}
}
