/*************************
* MIXINY PRO ODRÁŽKY
*************************/

@mixin bullet($bg: #bcbcbc, $border: $bg, $top: -0.08em) {
	&::before {
		box-sizing: border-box;
		position: relative;
		top: $top;
		margin-right: 7px;
		display: inline-block;
		content: encode-svg(
			'<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="7px" height="7px" viewBox="0 0 7 7"><path fill="#{$bg}" stroke="#{$border}" stroke-miterlimit="10" d="M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z"/></svg>'
		);
		vertical-align: middle;
		line-height: 0;
		overflow: hidden;

		@content;
	}
}

@mixin no-bullet {
	&::before {
		content: normal !important;
	}
}

/*****************************************
* Blackwell - MIXINS
*****************************************/

@mixin grid($breakpointMap...) {
   $nextMapIndex: 1;
   $firstBreakpoint: nth(nth($breakpointMap, $nextMapIndex), 1);
   $gap: 0;

   display: grid;

   @each $breakpoint in $breakpointMap {
      $nextMapIndex: $nextMapIndex + 1;
      $nextMapValue: if(length($breakpointMap) >= $nextMapIndex, nth(nth($breakpointMap, $nextMapIndex), 1), false);

      $currentBreakpoint: nth($breakpoint, 1);
      $amount: nth($breakpoint, 2);

      @if (length($breakpoint) == 4) {
         $rowGap: nth($breakpoint, 3);
         $colGap: nth($breakpoint, 4);
         $gap: unquote('#{$rowGap} #{$colGap}');
      } @else if (length($breakpoint) == 3) {
         $gap: nth($breakpoint, 3);
      }

      @if $nextMapValue != false {
         @include minmax($currentBreakpoint, $nextMapValue) {
            grid-template-columns: repeat($amount, 1fr);
            grid-gap: $gap;
            gap: $gap;
         }
      } @else {
         @include min($currentBreakpoint - 1px) {
            grid-template-columns: repeat($amount, 1fr);
            grid-gap: $gap;
            gap: $gap;
         }
      }
   }
}

@mixin steps($property, $baseValue, $breakpointMap...) {
   #{$property}: $baseValue;

   @each $breakpoint in $breakpointMap {
      @include min(nth($breakpoint, 1)) {
         #{$property}: nth($breakpoint, 2);
      }
   }
}

@mixin link($selector: a, $visited: true) {
   @if $selector != & {
      $selector: unquote($selector);

      @if $visited == true {
         #{$selector},
         #{$selector}:link,
         #{$selector}:visited {
            @content;
         }
      } @else {
         #{$selector},
         #{$selector}:link {
            @content;
         }
      }
   } @else {
      &,
      &:link,
      &:visited {
         @content;
      }
   }
}

@mixin link-visited($selector: a) {
   @if $selector != & {
      $selector: unquote($selector);

      #{$selector}:visited {
         @content;
      }
   } @else {
      &:visited {
         @content;
      }
   }
}

@mixin link-over($selector: a) {
   @if $selector != & {
      $selector: unquote($selector);

      #{$selector}:hover,
      #{$selector}:focus,
      #{$selector}:active {
         @content;
      }
   } @else {
      &:hover,
      &:focus,
      &:active {
         @content;
      }
   }
}

@mixin link-all($selector: a) {
   @if $selector != & {
      $selector: unquote($selector);

      #{$selector},
      #{$selector}:link,
      #{$selector}:visited,
      #{$selector}:hover,
      #{$selector}:focus,
      #{$selector}:active {
         @content;
      }
   } @else {
      &,
      &:link,
      &:visited,
      &:hover,
      &:focus,
      &:active {
         @content;
      }
   }
}

@mixin limit-items($limit, $selector: null) {
   @if $selector == null {
      $last: null;

      @each $s in & {
         $l: length($s);
         $last: nth($s, $l);
      }

      &:nth-of-type(#{$limit}) ~ #{$last} {
         display: none;
      }
   } @else {
      #{$selector}:nth-of-type(#{$limit}) ~ li {
         display: none;
      }
   }
}

@mixin font-face($fontFaceMap, $filePath, $fileName) {
   $family: map-get($fontFaceMap, family);
   $weight: if(map-has-key($fontFaceMap, weight), map-get($fontFaceMap, weight), normal);
   $style: if(map-has-key($fontFaceMap, style), map-get($fontFaceMap, style), normal);

   @font-face {
      font: {
         family: '#{$family}';
         weight: $weight;
         style: $style;
         display: swap;
      }

      src: url('#{$filePath}#{$fileName}.woff2') format('woff2'), url('#{$filePath}#{$fileName}.woff') format('woff');
   }
}

@mixin min($value) {
   @media screen and (min-width: $value) {
      @content;
   }
}

@mixin max($value) {
   @media screen and (max-width: $value) {
      @content;
   }
}

@mixin max-precise($value) {
   @if unitless($value) {
      @error "MIXIN MAX-PRECISE: $value musí mít jednotku";
   }

   $unit: unit($value);
   $mod: to-length(0.1, $unit);
   $value: $value - $mod;

   @media screen and (max-width: $value) {
      @content;
   }
}

@mixin minmax($value-min, $value-max) {
   @media screen and (min-width: $value-min) and (max-width: $value-max) {
      @content;
   }
}

@mixin minmax-precise($value-min, $value-max) {
   @if unitless($value-max) {
      @error "MIXIN MINMAX-PRECISE: $value-max musí mít jednotku";
   }

   $unit: unit($value-max);
   $mod: to-length(0.1, $unit);
   //@debug $increase;
   //@debug $mod;

   $value-max: $value-max - $mod;
   //@debug $value-max;

   @media screen and (min-width: $value-min) and (max-width: $value-max) {
      @content;
   }
}

@mixin quantity-query($condition, $selector: &) {
   $conditionComplexity: length($condition);

   $least: nth($condition, 2);
   $selectorMap: ();

   @each $s in & {
      $l: length($s);
      $last: nth($s, $l);

      $s: implode($s, ' ');

      @if $conditionComplexity == 2 {
         $cond: nth($condition, 1);

         @if $cond == min {
            $selectorMap: append($selectorMap, '#{$s}:nth-last-child(n+#{$least})', space);
            $selectorMap: append($selectorMap, '#{$s}:nth-last-child(n+#{$least}) ~ #{$last}', space);
         } @else if $cond == max {
            $selectorMap: append($selectorMap, '#{$s}:nth-last-child(-n+#{$least})', space);
            $selectorMap: append($selectorMap, '#{$s}:nth-last-child(-n+#{$least}) ~ #{$last}', space);
         } @else if $cond == exactly {
            $selectorMap: append(
               $selectorMap,
               '#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$least}):first-child',
               space
            );
            $selectorMap: append(
               $selectorMap,
               '#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$least}):first-child ~ #{$last}',
               space
            );
         }
      } @else {
         $most: nth($condition, 4);
         $selectorMap: append(
            $selectorMap,
            '#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$most}):first-child',
            space
         );
         $selectorMap: append(
            $selectorMap,
            '#{$s}:nth-last-child(n+#{$least}):nth-last-child(-n+#{$most}):first-child ~ #{$last}',
            space
         );
      }
   }

   $compound: implode($selectorMap, ', ');

   @at-root {
      #{$compound} {
         @content;
      }
   }
}

@mixin fill($top: 0, $right: 0, $bottom: 0, $left: 0, $position: absolute) {
   position: $position;
   top: $top;
   right: $right;
   bottom: $bottom;
   left: $left;
}

@mixin pad($color, $side: left, $element: before, $width: 999em) {
   $from: if($side == right, left, right);

   &::#{$element} {
      content: '';
      position: absolute;
      width: $width;
      #{$from}: 100%;
      top: 0;
      bottom: 0;
      background-color: $color;
   }
}

@mixin headings($from, $through) {
   $start: clamp-value($from, 1, 6);
   $end: clamp-value($through, 1, 6);
   $selectors: ();

   @for $level from $start through $end {
      $selectors: append($selectors, 'h#{$level}', comma);
   }

   $compound: implode($selectors, ', ');

   #{$compound} {
      @content;
   }
}

@mixin reset-input {
   border-radius: 0;
   -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   background: transparent none;
   box-shadow: none;

   &:focus {
      box-shadow: none;
   }
}

@mixin flex( $shortcuts... ) {
	$defs: (
		"df":    (display, flex),
		"dif":   (display, inline-flex),
		"fg0":   (flex-grow, 0),
		"fg1":   (flex-grow, 1),
		"fs0":   (flex-shrink, 0),
		"fs1":   (flex-shrink, 1),
		"fb1":   (flex-basis, 1),
		"fdr":   (flex-direction, row),
		"fdrr":  (flex-direction, row-reverse),
		"fdc":   (flex-direction, column),
		"fdcr":  (flex-direction, column-reverse),
		"jcfs":  (justify-content, flex-start),
		"jcfe":  (justify-content, flex-end),
		"jcsb":  (justify-content, space-between),
		"jcsa":  (justify-content, space-around),
		"jcc":   (justify-content, center),
		"aic":   (align-items, center),
		"ais":   (align-items, stretch),
		"aifs":  (align-items, flex-start),
		"aife":  (align-items, flex-end),
		"fww":   (flex-wrap, wrap),
		"fwn":   (flex-wrap, nowrap),
		"asfs":  (align-self, flex-start),
		"asfe":  (align-self, flex-end)
	);

	@each $key in $shortcuts {
		@if map-has-key($defs, $key) {
			$styles: map-get($defs, $key);
			$prop: nth($styles, 1);
			$value: nth($styles, 2);

			#{$prop}: #{$value};
		} @else {
			@error "Neplatná zkratka";
		}
	}
}
