.attachments {
	margin: 0 0 3em;
	grid-template-columns: 1fr;
	grid-gap: 1rem 0;
	padding: 0;

	.vismo-context-button.-edit {
		top: 0;
		left: -2.5rem;
		background-color: #C1C1C1;
		width: 20px;
		padding: 0.3rem 0.3rem 0rem;
		box-sizing: content-box;
		border-radius: 5px 5px 0 0;

		svg {
			height: 18px;
			filter: none;
		}
	}

	[data-group-element-id] .vismo-checkbox {
		top: 1.75em;
		left: -2.5rem;
		background-color: #C1C1C1;
		width: 20px;
		padding: 0 0.3rem 0.2rem;
		box-sizing: content-box;
		border-radius: 0 0 5px 5px;
		.vismo-checkbox__label:before,
		.vismo-checkbox__label:after{
			width: 20px;
			height: 20px;
			margin-top: -10px;
		}

		:not(:checked)+.vismo-checkbox__label:before {
			box-shadow: 0 0 0;
		}
	}
}

.attachment {
	text-align: left;

	&__link {
		$icon-width: 2rem;
		$icon-height: 3rem;
		$icon-clip: 0.75rem;
		$icon-radius: 0;

		display: inline-flex;
		position: relative;
		flex-direction: column;
		padding-left: calc(#{$icon-width} + 1rem);

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;
			width: $icon-width;
			height: $icon-height;
			background-color: #EEEEEE;
			border-radius: $icon-radius;
			margin-bottom: .75em;
			clip-path: polygon(0 0, #{($icon-width)-($icon-clip)} 0, #{$icon-width} #{$icon-clip}, 100% 100%, 0 100%, 0 0);
		}

		&::after {
			content: "";
			border-radius: 0 0 0 $icon-radius;
			width: $icon-clip;
			height: $icon-clip;
			background-color: #C1C1C1;
			position: absolute;
			left: #{($icon-width)-($icon-clip)};
			top: 0;
			z-index: 2;
			clip-path: polygon(0 0, 100% 100%, 0 100%, 0 0);
		}
	}

	&__title {
		display: block;
		word-wrap: break-word;
		margin: 0;
		font-weight: 700;
	}

	&__type {
		text-transform: uppercase;

		&::after {
			content: ",";
		}
	}

	&__details:not(:first-child) {
		position: relative;
		margin: 0 0 0;
		color: #646464;

		&::before {
			content: "(";
		}

		&::after {
			content: ")";
		}
	}

	&__link[data-attachment-type=""] {
		min-height: 2rem;

		.attachment__details:not(:first-child),
		.attachment__type {

			&::before,
			&::after {
				content: normal;
			}
		}

		&::after {
			content: normal;
		}

		&::before {
			margin: 0;
			width: 2rem;
			height: 2rem;
			clip-path: none;
			border-radius: 0;

			background: {
				image: encode-svg('<svg id="Component_5_1" data-name="Component 5 – 1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g id="Ellipse_31" data-name="Ellipse 31" fill="none" stroke="#c1c1c1" stroke-width="4"><ellipse cx="16" cy="16" rx="16" ry="16" stroke="none"/><ellipse cx="16" cy="16" rx="14" ry="14" fill="none"/></g><rect id="Rectangle_18" data-name="Rectangle 18" width="3.2" height="14.874" transform="translate(14.995 8.563)" fill="#c1c1c1"/><rect id="Rectangle_19" data-name="Rectangle 19" width="3.2" height="14.874" transform="translate(23.734 14.4) rotate(90)" fill="#c1c1c1"/></svg>');
				repeat: no-repeat;
				position: 50% 50%;
				color: transparent;
			}
		}

		&:hover {
			&::before {
				background-image: encode-svg('<svg id="Component_5_1" data-name="Component 5 – 1" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><g id="Ellipse_31" data-name="Ellipse 31" fill="none" stroke="rgb(220,24,52)" stroke-width="4"><ellipse cx="16" cy="16" rx="16" ry="16" stroke="none"/><ellipse cx="16" cy="16" rx="14" ry="14" fill="none"/></g><rect id="Rectangle_18" data-name="Rectangle 18" width="3.2" height="14.874" transform="translate(14.995 8.563)" fill="rgb(220,24,52)"/><rect id="Rectangle_19" data-name="Rectangle 19" width="3.2" height="14.874" transform="translate(23.734 14.4) rotate(90)" fill="rgb(220,24,52)"/></svg>');
			}
		}
	}
	@include link(".attachment__link") {
		text-decoration: none;
	}
	@include link-over(".attachment__title") {
		text-decoration: underline;
	}
}
