.events {
	.list {
		@include grid(550px 2 1rem, 800px 4 20px);
	}

	.event {
		position: relative;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		padding-bottom: 70%;
		border-radius: 12px;
		background: #dedede;
		z-index: 10;

		@include min(600px) {
			padding-bottom: 140%;
		}

		&__image {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			@include fill();
			z-index: -1;

			&:empty {
				transition: background-color .4s ease-in-out;
				background: #f5f5f5 encode-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 173 147.1" width="191" height="162" xml:space="preserve" fill="#e9e9e9"><path d="M151.2 26.4l8-8.2-6.2-12-10.5 9.4c-.3-3.8-1.2-8.2-4.4-15.6h-.1c-6 2.3-6.7 1.7-10.3 3.6 2.8 11.6 1.2 18.7-2.6 29.3l-7.8 7.8c-.4-3.8-1.8-8.5-4.9-15.7-6 2.3-6.3 1.4-9.9 3.3-.6 10.4.8 19.5-3 30.2l-13 13-13-13c-3.8-10.7-2.3-19.8-3-30.2-3.6-1.9-3.9-1-9.9-3.3-3.1 7.2-4.5 11.8-4.9 15.7l-7.8-7.8c-3.8-10.6-5.3-17.7-2.6-29.3C41.7 1.7 41 2.3 35 0h-.1c-3.2 7.4-4.2 11.8-4.4 15.6L20 6.2l-6.2 12 8 8.2c-5.2.2-15.9.1-21.8 3.2 3.5 5.5 3.2 6.4 5.1 10.1 10.4.6 18.8-1.2 29.4 2.6l8.1 8.1c-4.2.7-9.3 2.6-16.9 5.9 2.3 6 .9 4.8 2.8 8.4 10.4.6 20.9-.7 31.6 3.1l15.1 15.1-30.8 30.9c-.4-4.2-1.9-9.3-5.1-16.8-6 2.3-6.4 3.2-10.1 5.1-.6 10.4 1.2 18.8-2.6 29.4l-5.1 5.1c4 4.9 8.1 9 12.8 10.5l5.7-6.5c10.7-3.8 18.8-2.9 29.2-3.6 1.9-3.6 3.1-3.1 5.4-9.1-7.5-3.2-12.5-4.7-16.8-5.1l28.7-28.7 28.7 28.7c-4.2.4-9.3 1.9-16.8 5.1 2.3 6 3.5 5.5 5.4 9.1 10.4.6 18.5-.2 29.2 3.6l5.7 6.5c4.6-1.5 8.8-5.6 12.8-10.5l-5.1-5.1c-3.8-10.7-2-19.1-2.6-29.4-3.6-1.9-4-2.8-10.1-5.1-3.2 7.5-4.7 12.6-5.1 16.8L97.8 82.9l15.1-15.1c10.7-3.8 21.2-2.5 31.6-3.1 1.9-3.6.5-2.4 2.8-8.4-7.6-3.3-12.7-5.3-16.9-5.9l8.1-8.1c10.7-3.8 19.1-2 29.4-2.6 1.9-3.6 1.6-4.5 5.1-10.1-5.9-3.1-16.5-3-21.8-3.2z"/></svg>') no-repeat 50% 35%;

				@include link-over(&) {
					background-color: gray;
				}
			}

			img {
				position: absolute;
				width: auto;
				max-width: none;
				height: 100%;
				z-index: -1;
			}
		}

		&__day {
			position: absolute;
			z-index: 20;
			top: 1.5rem;
			left: 1.5rem;
			max-width: calc(100% - 4rem);
			font-size: rem(15px);
			font-weight: 700;
			padding: 0.75rem 1rem;
			border-radius: 8px;
			background-color: $color-brand;
			color: $color-light;

			&-fullday:not(:first-child) {
				&::before {
					content: " - ";
				}
			}

			//&-month {
			//	text-transform: capitalize;
			//}

			&-year {
				position: absolute !important;
				left: -9999rem !important;
				top: auto;
				outline: 0;
				width: 0;
				height: 0;
				overflow: hidden;
			}
		}

		&__link {
			margin-bottom: 0;
			&::before{
				content: "";
				@include fill;
			}
			@include link(&){
				color: $color-light;
				text-decoration: underline;
			}
			@include link-over(&){
				text-decoration: none;
			}
		}

		&__title {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 105px;
			z-index: 15;
			bottom: 0;
			left: 0;
			right: 0;
			padding: 0.5rem 1rem;
			font-size: rem(17px);
			margin: 0 0 0;
			font-weight: 700;
			color: inherit;
			text-align: center;
			text-decoration: inherit;
			&::before{
				position: absolute;
				content: "";
				bottom: 0;
				left: 0;
				right: 0;
				background: transparent url(/images/local/v-202411080924/homepage-events-shadow.png) repeat-x 0 0;
				top: -4rem;
				z-index: -1;
			}
		}

		&__dates {
			border-color: #fff;
			padding-bottom: 0.5rem;
		}

		&__type {
			&__link {
				&::after {
					margin-left: .25rem;
				}
			}
		}

		&__status,
		&__description {
			position: absolute;
			@include flex(df, aic, jcc);
			@include fill;
			margin: 0;
			background-color: rgba(#000000, .6);
			font-size: rem(18px);
			z-index: 2;

			&,
			&.-primary {
				color: #fff;
			}
		}

		&__description {
			opacity: 0;
			z-index: 3;

			@include link-over(&) {
				opacity: 1;

				+.event__status {
					opacity: 0;
				}
			}
		}
	}
}

.events-by-day {
	.list {
		&__item {}
	}

	&__more {
		margin-top: 0;
		text-align: left;
	}

	.event {

		&__image {
			position: relative;
			margin: 0 0;
			overflow: hidden;
			height: 0;
			padding-bottom: ratio(3, 2);
			border-radius: 12px;

			&:empty {
				transition: background-color .4s ease-in-out;
				background: #f5f5f5 encode-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 173 147.1" width="191" height="162" xml:space="preserve" fill="#e9e9e9"><path d="M151.2 26.4l8-8.2-6.2-12-10.5 9.4c-.3-3.8-1.2-8.2-4.4-15.6h-.1c-6 2.3-6.7 1.7-10.3 3.6 2.8 11.6 1.2 18.7-2.6 29.3l-7.8 7.8c-.4-3.8-1.8-8.5-4.9-15.7-6 2.3-6.3 1.4-9.9 3.3-.6 10.4.8 19.5-3 30.2l-13 13-13-13c-3.8-10.7-2.3-19.8-3-30.2-3.6-1.9-3.9-1-9.9-3.3-3.1 7.2-4.5 11.8-4.9 15.7l-7.8-7.8c-3.8-10.6-5.3-17.7-2.6-29.3C41.7 1.7 41 2.3 35 0h-.1c-3.2 7.4-4.2 11.8-4.4 15.6L20 6.2l-6.2 12 8 8.2c-5.2.2-15.9.1-21.8 3.2 3.5 5.5 3.2 6.4 5.1 10.1 10.4.6 18.8-1.2 29.4 2.6l8.1 8.1c-4.2.7-9.3 2.6-16.9 5.9 2.3 6 .9 4.8 2.8 8.4 10.4.6 20.9-.7 31.6 3.1l15.1 15.1-30.8 30.9c-.4-4.2-1.9-9.3-5.1-16.8-6 2.3-6.4 3.2-10.1 5.1-.6 10.4 1.2 18.8-2.6 29.4l-5.1 5.1c4 4.9 8.1 9 12.8 10.5l5.7-6.5c10.7-3.8 18.8-2.9 29.2-3.6 1.9-3.6 3.1-3.1 5.4-9.1-7.5-3.2-12.5-4.7-16.8-5.1l28.7-28.7 28.7 28.7c-4.2.4-9.3 1.9-16.8 5.1 2.3 6 3.5 5.5 5.4 9.1 10.4.6 18.5-.2 29.2 3.6l5.7 6.5c4.6-1.5 8.8-5.6 12.8-10.5l-5.1-5.1c-3.8-10.7-2-19.1-2.6-29.4-3.6-1.9-4-2.8-10.1-5.1-3.2 7.5-4.7 12.6-5.1 16.8L97.8 82.9l15.1-15.1c10.7-3.8 21.2-2.5 31.6-3.1 1.9-3.6.5-2.4 2.8-8.4-7.6-3.3-12.7-5.3-16.9-5.9l8.1-8.1c10.7-3.8 19.1-2 29.4-2.6 1.9-3.6 1.6-4.5 5.1-10.1-5.9-3.1-16.5-3-21.8-3.2z"/></svg>') no-repeat center center;

				@include link-over(&) {
					background-color: gray;
				}
			}

			img {
				position: absolute;
				@include fill();
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
				transition: transform .3s ease-in-out;
				transform: scale(1);
			}
		}
		&__types {
			position: relative;
			@include flex(df, fww, aic);
			gap: 0.25rem;
		}

		&__type {
			&__link {
				@include link-over(&) {
					text-decoration: underline;
				}
			}
		}
		@include min(450px){
			&--block{
				display: grid;
				grid-template-columns: 140px 1fr;
				gap: 0 1rem;
				.event__image{
					padding-bottom: 135%;
					&:empty {
						background: #f5f5f5 encode-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 173 147.1" width="191" height="162" xml:space="preserve" fill="#e9e9e9"><path d="M151.2 26.4l8-8.2-6.2-12-10.5 9.4c-.3-3.8-1.2-8.2-4.4-15.6h-.1c-6 2.3-6.7 1.7-10.3 3.6 2.8 11.6 1.2 18.7-2.6 29.3l-7.8 7.8c-.4-3.8-1.8-8.5-4.9-15.7-6 2.3-6.3 1.4-9.9 3.3-.6 10.4.8 19.5-3 30.2l-13 13-13-13c-3.8-10.7-2.3-19.8-3-30.2-3.6-1.9-3.9-1-9.9-3.3-3.1 7.2-4.5 11.8-4.9 15.7l-7.8-7.8c-3.8-10.6-5.3-17.7-2.6-29.3C41.7 1.7 41 2.3 35 0h-.1c-3.2 7.4-4.2 11.8-4.4 15.6L20 6.2l-6.2 12 8 8.2c-5.2.2-15.9.1-21.8 3.2 3.5 5.5 3.2 6.4 5.1 10.1 10.4.6 18.8-1.2 29.4 2.6l8.1 8.1c-4.2.7-9.3 2.6-16.9 5.9 2.3 6 .9 4.8 2.8 8.4 10.4.6 20.9-.7 31.6 3.1l15.1 15.1-30.8 30.9c-.4-4.2-1.9-9.3-5.1-16.8-6 2.3-6.4 3.2-10.1 5.1-.6 10.4 1.2 18.8-2.6 29.4l-5.1 5.1c4 4.9 8.1 9 12.8 10.5l5.7-6.5c10.7-3.8 18.8-2.9 29.2-3.6 1.9-3.6 3.1-3.1 5.4-9.1-7.5-3.2-12.5-4.7-16.8-5.1l28.7-28.7 28.7 28.7c-4.2.4-9.3 1.9-16.8 5.1 2.3 6 3.5 5.5 5.4 9.1 10.4.6 18.5-.2 29.2 3.6l5.7 6.5c4.6-1.5 8.8-5.6 12.8-10.5l-5.1-5.1c-3.8-10.7-2-19.1-2.6-29.4-3.6-1.9-4-2.8-10.1-5.1-3.2 7.5-4.7 12.6-5.1 16.8L97.8 82.9l15.1-15.1c10.7-3.8 21.2-2.5 31.6-3.1 1.9-3.6.5-2.4 2.8-8.4-7.6-3.3-12.7-5.3-16.9-5.9l8.1-8.1c10.7-3.8 19.1-2 29.4-2.6 1.9-3.6 1.6-4.5 5.1-10.1-5.9-3.1-16.5-3-21.8-3.2z"/></svg>') no-repeat center center / 100px;

					}
				}
			}
		}
		@include min(800px){
			&--block{
				gap: 0 40px;
			}
		}
	}
}

.event {
	&-datetime {
		margin: 0;

		&__container {
			.list {
				margin-bottom: 1rem;
			}
		}

		&__description {
			margin: 0;
		}
	}

	&-thumb-desc {
		display: flex;
		flex-wrap: wrap;
		margin-top: 1rem;
		margin-bottom: 1rem;

		.article__image {
			margin: 0;
			padding: .4rem 0 0rem;
		}

		.event__description {
			.text-component p:first-child {
				margin-top: 0;
			}
		}

		@include min(1024px) {

			>.article__image,
			>div:first-child:not(.event__description) {
				max-width: 50%;
				padding-right: 6%;
			}

			.event__description {
				flex: 1;
			}
		}
	}

	&__day {
		color: $color-brand;
		@include min(1024px){
			font-size: rem(32px);
			margin-bottom: 0.5em;
		}
	}

	&__list {
		list-style-type: none;
		padding-left: 0;
		//@include grid(550px 2 1rem, 1400px 2 0 3rem);
	}

	&__item {
		margin-bottom: 1.5rem;
	}

	&__image {
		margin: 0;
	}

	&--block {
		position: relative;
	}

	&__link {
		display: inline-block;
		margin-bottom: 0.5rem;
		&.-cover{
			&::after{
				content: "";
				@include fill;
			}
		}
		@include link(&){
			color: $color-secondary;
		}
	}

	&__title {
		color: inherit;
		font-size: 1.25rem;
		display: inline;
	}

	&__types {
		line-height: 1.25;
	}

	&__description {
		margin-top: 0.5rem;
	}

	&__dates {
		margin: 0.75rem 0 .5rem;
		color: $color-brand;
	}

	&__status {
		display: block;
		margin-bottom: 0.5rem;
		color: #CA1517;
		font-size: rem(17px);

		&.-primary {
			color: $color-brand;
		}
	}
}

.article-event {
	&__perex {
		margin-top: .5em;
	}

	&__types {
		.list {
			display: flex;
			flex-wrap: wrap;
			gap: 8px 10px;
			margin: 0 0 1rem;

			&__item {
				display: inline-flex;
				margin: 0;
				line-height: 1.25;
				align-items: center;
			}
		}
	}
}

.article-event__type,
.event__type__link {
	font-size: rem(14px);
	font-weight: 700;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	min-width: 1.845rem;
	min-height: 1.845rem;
	border: 2px solid #9D9D9D;
	border-radius: 6px;
	text-decoration: none;
	padding: 0 .5rem;
}
