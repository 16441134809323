.article__tiles {
	@include min(600px) {
		.-nosidebar & {
			margin-top: -8.4rem;
			[vuedart="vuedart"] & {
				margin-top: 0;
			}
		}
	}
}

.tiles {
	&__list {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
		grid-gap: 20px;
		gap: 20px;
		padding: 0 0 1rem;
		margin: 2em 0 2em;
		list-style-type: none;
		@include min(600px) {
			.article__body.-calendar &{
				grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
			}
		}
		@include min(1024px) {
			grid-gap: 40px;
			gap: 40px;
		}
	}
}

.tile {
	&__item {
		display: flex;
		flex-direction: column;
		padding: 0;
		width: 100%;
		font-size: rem(18px);
		font-family: $font-headings;
		font-weight: 700;
	}

	&__link {
		position: relative;
		padding: 1.25rem 1rem;
		@include flex(df, aic, jcc);
		text-align: center;
		flex-grow: 1;
		@include min(1024px) {
			min-height: 90px;
		}

		@include link(&) {
			background-color: $color-brand;
			color: $color-light;
			text-decoration: none;
			border-radius: 0.5rem;
		}

		@include link-over(&) {
			background-color: $color-secondary;
			color: $color-light;
		}
	}
}
